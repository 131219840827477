@import '../variables';
@import '../abstracts/colors';
@import '../abstracts/typography';

// NEW

.input-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    //gap: $xs;


    &>div.form-input {
        border: transparent;
        display: flex;
        flex-direction: column;
        //gap: 6px;
        position: relative;

        &.number {
            outline: none;
            border: none;
            width: 90%;
            cursor: pointer;
            text-align: right;
            margin-right: 5px;
        }

    }

    .width-with-add-btn {
        width: calc(100% - 36px);

        .add-btn {
            transform: translateY(0);
            right: -36px;
        }
    }

    label {
        color: $text-primary;
        font-size: 15px;
        margin-bottom: 10px;
        font-weight: 600;
        text-transform: capitalize;
    }

    .error {
        margin-bottom: 0px;
        font-size: 12px;
        color: red;
        text-transform: none;
    }

    & input,
    textarea {
        outline: none;
        border: none;
        color: $text-primary;
        font-size: 13px;
        background-color: transparent;
        width: 100%;
        border-bottom: 1px solid $yellow;
    }

    & textarea {
        resize: none;
        height: 5rem;
    }

    & .minmax-input {
        display: flex;
        background-color: transparent;

        & input {
            width: 50%;
        }
    }

    &--disabled {

        cursor: not-allowed;
        label,
        span,
        input,
        textarea {
            color: #333F48;

            &:disabled {
                background-color: #cecece;
                cursor: not-allowed;
                border-bottom: 1px solid $light-grey;
            }
        }
    }

    select {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid $yellow;
        outline: none;
        color: $text-primary;
        font-size: 13px;
    }

    &.number {
        outline: none;
        border: none;
        width: 90%;
        cursor: pointer;
        text-align: right;
        margin-right: 5px;
    }
}



.input-text {
    display: flex;
    border: none;
    outline: none;
    flex-direction: column;
    background-color: transparent;
}

.horiz-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.check-input {
    position: absolute;
    top: 11px;
    left: 0;
    height: 30px;
    width: 30px;

    >* {
        position: absolute;
        top: 3px;
        left: 3px;
        height: 100%;
        width: 100%;
    }

    & input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
        font: inherit;
        color: currentColor;
        z-index: 2;

        &:disabled {
            cursor: not-allowed;
        }
    }

}

.inline-input {
    outline: none;
    border: none;
    width: 90%;
    cursor: pointer;

    &--fresado {
        width: 30px;
        outline: none;
        border: none;
        cursor: pointer;
    }

    &.number {
        outline: none;
        border: none;
        width: 90%;
        cursor: pointer;
        text-align: right;
        margin-right: 5px;
    }

    &.edited {
        background-color: $light-yellow;

        &.number {
            outline: none;
            border: none;
            width: 90%;
            cursor: pointer;
            text-align: center;
        }
    }
}

.input-with-btn {
    display: flex;
    justify-content: space-between;

    &>* {
        width: 100%;
    }

    & button {
        position: relative;
    }
}

.import-study {
    //font-size: 1rem;
    display: flex;
    align-items: flex-start;
    gap: 24px;
}

.study-detail {
    position: relative;
    width: 100%;
    width: fit-content;
    display: flex;
    padding-right: 56px;
}

input {
    pointer-events: auto;
}