@import '../abstracts/colors';

.sidebar {
    // border: 2px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $yellow;

    &--hidden {
        display: none;
    }
   
    &__brand {
        padding: 36px;
        position: relative;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        gap: 12px;
        & > div {
            display: flex;
            flex-direction: column;
            gap: 6px;
        }
        img {
            width: 100%;
        }
        & .icon--pointer {
            position: absolute;
            top: 28x;
            right: 0;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        & label {
            color: $dark-grey;
            font-size: 1rem;
            font-weight: 700;
        }
        & .logout-button{
            background-color: $dark-grey;
            color: $white;
            font-size: 0.9rem;
            height: 2rem;
            width: fit-content;
            padding: 0 12px;
            }
    }
    &__navigation {
        padding: 0 24px;
        background-color: $bg-primary;
        justify-self: flex-start;
        height: 100%;
        border-top: 6px solid $dark-grey; 
        & h4 {
            border-bottom: solid 1px $yellow;
            padding: 24px 12px 12px;
            color: $dark-grey;
            margin-bottom: 24px;

        }
        & ul {
            list-style: none;
            padding: 0;
            & li > div, li > a {
                display: flex;
                align-items: center;
                gap: 12px;
                padding: 12px 0;
                text-decoration: none;
                color: $dark-grey;
                cursor: pointer;
            }

            & .nav-item {
                padding: 0 12px;
                margin: 6px 0;
                border-radius: 12px; 
                &:hover {
                    background-color:$light-grey;
                   border-radius: 12px;
                }
                &--selected {
                    background-color: $yellow;
                    border-radius: 12px;
            }
            }

            & .nav-option {
                text-decoration: none;
                color: $dark-grey;
            li {
                padding: 12px;
                margin-left: 20%;
                // padding-left: 30%;
                &::before {
                    content: "";
                    background-color: $yellow;
                    display: inline-block;
                    width: 0.5rem;
                    height: 0.5rem;
                    border-radius: 50%;
                    margin-right: 1rem;
                    // font-size: 3rem;
                    // height: 1rem;
                }
                &:hover {
                    background-color: $light-grey;
                    border-radius: 12px;
                }
                
            }
            &--selected {
                background-color: $yellow;
            }
        }

            & .nav-item-content {
                width: 100%;
                position: relative;
                // display: flex; 
                // justify-content: space-between;
                & .expand-icon   {
                    position: absolute;
                    top: 10px;
                    right: 0;
                    justify-self: right;
                    color: $yellow;
                }
            }
        }
    }
    &__version {
        background-color: $bg-tertiary;
        display: flex;
        flex-flow: nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 6px 24px;
        & > a {
            color: $dark-grey;
            outline: none;
            text-decoration: none;
            font-size: 1rem;
        }
    }
}