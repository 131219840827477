@import "../../assets/styles/settings/colors";
@import '../../assets/styles/abstracts/colors';

.ofertas {
	&__grid {
		display: block;
		height: 59vh;
		overflow-y: auto;


		.footer-section {
			width: 100%;
		}
	}
}

.ofertas-form {
	// border: solid 2px blue;
	width: 100%;

	// .clone-btn {
	// 	display: flex;
	// 	justify-content: end;
	// 	align-items: center;
	// }
	
	.grid-section {
		display: grid;
		grid-auto-flow: row;
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 10px;
		// border: solid 2px blue;
	}

	.grid-section-fp {
		display: grid;
		grid-auto-flow: row;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		column-gap: 16px;
		
	}

	.clientes-contacts {
		// border: 2px solid red;
		height: 100%;
		margin-top: 16px;
		.contacts-back {
			padding: 15px;
			box-shadow: none;
			border-radius: 0 5px 5px 5px;
			overflow-x: auto;
			overflow-y: hidden !important;
			height: fit-content;
			// max-height: 37vh;
		}
	}
	
	.contacts--grid-header, .contacts--grid-item{
		color: $primary_dark_color;
		font-size: 13px;
		display: grid;
		gap: 15px;
		display: flex;
		justify-content: space-between;
		// grid-template-columns: repeat(7, minmax(100px, 1fr)) 20px 20px;
		// grid-template-columns: repeat(6, minmax(100px, 1fr)) 40px 40px;
		// grid-template-columns: repeat(6, minmax(100px, 1fr));
		grid-auto-flow: column dense;
		padding: 0px 8px 8px 0px;
		label {
			margin-bottom: 5px;
			// padding-left: 10px;
			width: 100%;
		}
		
	}
	.float-right{
		float: right;
	}

	.google-drive{ 
		margin-top: 25px;
	}
	
	.contacts--grid-header {
		align-items: center;
		margin-bottom: 8px;
		padding-bottom: 0px;
		padding-right: calc(16px + 30px);
		padding-left: 10px;
		
		&--estudios {
			display: flex;
			height: 30px;
			gap: 15px;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 8px;
			margin-left: calc(30px + 10px + 15px);
			padding-right: calc(16px + 24px * 2 + 8px + 15px);
			position: relative;
			>label {
				width: 100%;
			}
			.buttons-container {
				position: absolute;
				top: 0;
				right: 16px;
				width: 60px;;
			}
		}

		&--seguimiento-ofertas {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-right: calc(15px + 30px + 15px + 30px + 8px + 8px);
			margin-left: 10px;
			margin-bottom: 8px;
			gap: 15px;
			>label {
				width: 300px;
				&:last-of-type  {
					width: 100%;
				}
			}
			.buttons-container {
				position: absolute;
				top: 0;
				right: 16px;
				top: 15px;
				width: 60px;;
			}
		}

		&--presupuestos {
		display: flex;
		gap: 15px;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
		margin-right: calc(16px + 8px + 24px + 15px);
		margin-left: calc(30px + 10px + 15px);
		padding-left: 10px;
			label {
				width: 100%;
			}
		}

		&--clausulas-oferta {
			display: flex;
			gap: 15px;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 8px;
			margin-right: calc(16px + 8px + 24px + 15px);
			margin-left: calc(16px + 6px + 8px);
			label {
				width: 100%;
			}
		}
	}
	
	.contacts--grid-item {
		align-items: center;
		background-color: #a5cde286;
		min-width: fit-content;
		position: relative;
		padding-left: 10px !important;
		
		>div{
			// border: solid 2px lightgreen;
			// width: 100%;
		}
		>.inline-grid-checkbox {
			height: fit-content;
			width: calc(10px + 30px);
			margin-right: 30px;
			width: fit-content;
		}

		&.selected {
			background-color: #ffcc0078;
		}
	}
	
	.seguimiento-ofertas {
		>div {
			width: 300px;
			gap: 15px;
			&:last-of-type  {
				width: 100%;
			}
	}

	}
	.contact-dropdown {
		text-align: center;
		min-width: 100px;
		height: min-content;
		font-size: 11.9px;
		// background-color: $pavasal-gray;
		padding: 8px 0 8px 0;
		// border-radius: 5px;
		background-color: $dark-grey !important;
		border-radius: 0 !important;
		color: $white-font;
		cursor: pointer;
		&:hover {
			background-color: $pavasal;
		}
	
		.contact-dropdown-content {
			z-index: 9999;
			position: relative;
			width: inherit;
			max-height: 200px;
			.dropdown-item {
				.dropdown-options__option {
					padding: 0px;
				}
				padding: 0 0 0 8px;
				height: 25px;
				text-align: left;
				i {
					font-size: 18px;
				}
			}
	
		}
	}
	
	.contact-btn {
		width: 30px;
		height: 30px;
		padding: 0px;
	}

	.remove {
		&:hover {
			background-color: $color-red;
		}
	}

	.buttons-container {
		display: flex;
		gap: 10px;
		width: 60px;
		align-items: center;
	}

	.contacts--grid-items-container {
		overflow-y: scroll;
		height: 30vh;
		overflow-x: hidden;
	}

	.single-row textarea {
		height: fit-content;
	}
}
