@import '../abstracts/colors';

.icon-button {
    background-color: transparent;
    height: 24px;
    width: 24px;
    margin-left: 12px;
    color: $dark-grey;
    // border: 2px solid red;
    &:hover:enabled {
        background-color: $yellow;
    }
    &:disabled {
        // color: $bg-primary;
        
        background-color: transparent;
        & span {
            color: $medium-grey;
            // border: 2px solid red;
        }
    }
}

// .icon-button {
//     background-color: $bg-primary;
//     height: 24px;
//     width: 24px;

//     & > .material-icons {
//         color: $yellow;
//     }
//     &:hover {
//         & > .material-icons {
//         color: $dark-grey;
//     }
// }

// }