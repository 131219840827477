@import '../../../../../assets/styles/abstracts/colors';

.form-input{
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	}

	.input-yellow{
		background: $light-yellow;
	}
}