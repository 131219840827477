.filters-modal-container {
	display: flex;
	gap: 24px;
	align-items: center;

	.open-btn {
		background-color: transparent;
		display: flex;
		justify-content: center;
		align-items: center;	
		font-size: larger;
		font-weight: bolder;
		.icon {
			font-size: 18px;
		}
	}

}