.icon {

    vertical-align: middle;
    
    &--visible {
        display: block;
    }

    &--pointer {
        cursor: pointer;
    }

    &--hide {
        display: none;
    }
}


