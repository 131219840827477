@import '../abstracts/colors';

.add-edit-view-container {
    background-color: $white;
    height: 100%;

    .add-edit-header {
    background-color: $black;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 48px;
        & > h4 {
        color: $yellow;
        font-size: 1.5rem;
        font-weight: 400;
        }
    }   
    
    & > .add-edit-body {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    }

    .single-row {
        position: relative;
        // margin-bottom: 24px;
    }

    //
    .table-container {
        margin-top: 10px;
        width: 100%;
        height: 300px;
        overflow-x: auto; /* Añade scroll horizontal */
      }
      
      table {
        width: 100%;
        border-collapse: collapse;
      }

      thead {
        // display: table-header-group; /* Asegura que el thead se comporte como un grupo de cabecera de tabla */
        position: sticky;
        top: 0;
        background-color: white;
      }
      
      tbody {
        max-height: 300px; /* Ajusta la altura según tus necesidades */
      }
      
      thead th{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      
      tbody tr {
        // display: table;
        width: 100%;
      }
      
    //

    .row-of-two {
        display: inline-grid;
        position: relative;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px 24px;
        margin-bottom: 18px;
        & > * {
            width: 100%;
        }
    }

    .row-of-three {
        position: relative;
        display: inline-grid;
        width: 100%;
        height: 100%;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px 24px;
        margin-bottom: 18px;
        // box-shadow: inset 0 0 0 2px red;

        & > * {
            width: 100%;
        }
    }

    .row-of-five {
        position: relative;
        display: inline-grid;
        width: 100%;
        height: 100%;
        grid-template-columns: repeat(2, 0.5fr) repeat(3, 1fr);
        gap: 12px 24px;
        margin-bottom: 18px;
        // box-shadow: inset 0 0 0 2px red;

        & > * {
            width: 100%;
        }
    }

    .row-of-four {
        position: relative;
        display: inline-grid;
        width: 100%;
        height: 100%;
        grid-template-columns: repeat(4, 1fr);
        gap: 12px 24px;
        margin-bottom: 18px;
        // box-shadow: inset 0 0 0 2px red;

        & > * {
            width: 100%;
        }
    }

    .row-of-five {
        position: relative;
        display: inline-grid;
        width: 100%;
        height: 100%;
        grid-template-columns: repeat(5, 1fr);
        gap: 12px 24px;
        margin-bottom: 18px;
        // box-shadow: inset 0 0 0 2px red;

        & > * {
            width: 100%;
        }
    }

    .title {
        border-bottom: 1px solid $yellow;
        color: $text-primary;
        padding: 12px 0;
        margin: 12px 0 24px;
        display: none;
    }
}

.unidad-medida {
    max-width: calc(100% - 48px);
}

.unidad-medida-save-icon {
    height: 24px;
    bottom: 6px;
    top: auto;
}