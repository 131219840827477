@import "../../../../assets/styles/settings/_colors.scss";

.dropdownInline {
  display: flex;
  justify-content: center;
  font-size: 11.9px;
  background-color: $filter-element;
  border-radius: 3px;
  box-shadow: $box-shadow;
  height: fit-content;
  width: 100%;
  padding: 8px 0 8px 0;
  color:  $_white;
  cursor: pointer;
  &:hover {
    background-color: $pavasal;
  }

  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  label, b {
    cursor: pointer;
    &:hover {
      background-color: $pavasal;
    } 
  }


  &__content {
    color: black;
    background-color: $card-filter-background;
    border-radius: 5px;
    box-shadow: $box-shadow;
    max-height: 75vh;
    margin-top: 1.10em;
    min-width: 100px;
    overflow: auto;
    position: absolute;
    z-index: 1;

    &.no-children-content {
      margin-top: 0;
    }
  }

  &__content--visible {
    display: block;
    animation-name: fadeIn;
    animation-duration: 0.3s;
    font-size: 11.9px;
  }
  
  &__content--hide {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}