@import '../abstracts/colors';

.flex-horizontal {
    display: flex;
    align-items: flex-end;
    gap: 24px;
    // height: 50px; 
    border-bottom: solid 1px transparent;
    flex-wrap: nowrap !important;
    & > div {
        overflow-x: hidden;
    }
}

.icons-horizontal {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    gap: 6px;
    padding: 0 6px;
}

.icons-horizontal-inlinegrid {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    // gap: 0px;
    // padding: 0 -1px;

    .icon-button {
        margin-left: 1px;
    }
}

.one-row {
    display: flex;
    flex-wrap: nowrap !important;
    align-items: flex-end;
    gap: 24px;
}

.padding-bg {
    padding: 0 24px;
    position: relative;
}

.accent-bg {
    background-color: $bg-secondary;
    // background-color: $bg-tertiary;
    margin: 24px 0;
    border-radius: 12px;
    position: relative;
}

.absolute-right {
    position: absolute;
    top: 0;
    right: 12px;
}