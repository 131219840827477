.tabs-container {
    margin-top: 24px;
}

.react-tabs {
    max-height: calc(100% - 185px);
    overflow-y: auto;

    .tabs-unidadObra {
        min-height: 300px;
        max-height: 30vh;
    }
}

.react-tabs__tab-list {
    border-bottom: 1px solid $yellow;
    margin: 0 0 24px;
    padding: 0;
}

// .react-tabs__tab {
//     display: inline-block;
//     border: 1px solid transparent;
//     border-bottom: none;
//     bottom: -1px;
//     position: relative;
//     list-style: none;
//     padding: 6px 12px;
//     cursor: pointer;
// }

.react-tabs__tab--selected {
    // background: #fff;
    border-color: $yellow;
    // color: black;
    border-radius: 0;
}