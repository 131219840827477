    @import "../../assets/styles/settings/colors";
	@import '../../assets/styles/abstracts/colors';

.clientes {
	&__grid {
		display: block;
		height: 59vh;
		overflow-y: auto;


		.footer-section {
			width: 100%;
		}
	}
}

.promotores-ofertas-form {
	
	.grid-section {
		display: grid;
		grid-auto-flow: row;
		grid-template-columns: 1fr 1fr;
		column-gap: 10px;
		
	}

	.grid-section-fp {
		display: grid;
		grid-auto-flow: row;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		column-gap: 10px;
		
	}

	// .clientes-contacts {
	// 	margin-top: 16px;
	// 	// border: 2px solid green;
	// 	.contacts-back {
	// 		padding: 15px;
	// 		box-shadow: none;
	// 		border-radius: 0 5px 5px 5px;
	// 		overflow-x: auto;
	// 		position: relative;
	// 		overflow-y: hidden !important;
	// 		height: fit-content;
	// 		max-height: 37vh;
	// 	}
	// }
	
	// .contacts--grid-header, .contacts--grid-item {
	// 	color: $primary_dark_color;
	// 	font-size: 13px;
	// 	display: grid;
	// 	gap: 15px;
	// 	grid-template-columns: repeat(6, minmax(100px, 1fr)) 40px 40px;
	// 	grid-auto-flow: column dense;
	// 	padding: 0px 8px 8px 0px;
	// 	label {
	// 		min-width: 100px;
	// 		margin-bottom: 5px;
	// 		padding-left: 10px;
	// 	}
	// }
	
	.contacts--grid-header--fp, .contacts--grid-item--fp {
		color: $primary_dark_color;
		font-size: 13px;
		display: grid;
		gap: 15px;
		grid-template-columns: repeat(4, minmax(190px, 1fr)) 40px 40px;
		grid-auto-flow: column dense;
		label {
			min-width: 100px;
		}
	}
	.contacts--grid-header {
		align-items: center;
		margin-bottom: 8px;
		padding-bottom: 0px;
		margin-right: 16px;
	}

	.contacts--grid-items-container {
		overflow-y: scroll;
		position: relative;
		// max-height: fit-content;
		height: 30vh;
	}
	
	.contacts--grid-item {
		align-items: center;
		background-color: #a5cde286; 
		min-width: fit-content;
		padding-left: 10px !important;
		height: fit-content;
	}

	.contacts--grid-header--fp {
		align-items: center;
		margin-bottom: 8px;
		padding-bottom: 0px;
	}

	.contacts--grid-item--fp {
		align-items: center;
		background-color: #a5cde286; 
		min-width: fit-content;
	}
	
	.contact-dropdown {
		text-align: center;
		min-width: 100px;
		height: min-content;
		font-size: 11.9px;
		background-color: $dark-grey !important;
		// border: 2px solid red;
		padding: 8px 0 8px 0;
		border-radius: 0 !important;
		color: $white-font;
		cursor: pointer;
		position: relative;
		&:hover {
			background-color: $pavasal;
		}

		.dropdown-options-container {
			// TODO: try changing position here
			// overflow: visible;
			position: relative;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}
	
		.contact-dropdown-content {
			z-index: 99;
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			overflow-y: hidden;
			height: fit-content;
			.dropdown-item {
				.dropdown-options__option {
					padding: 0px;
				}
				padding: 0 0 0 8px;
				height: 25px;
				min-width: 100px;
	
				i {
					font-size: 18px;
				}
			}
	
		}
		.dropdown-up {
			top: auto;
			bottom: 100%;
		}
	}
	
	// .dropdown-cell {
	// 	display: grid;
	// 	grid-template-rows: auto 30%;
	// 	position: relative;

	// 	label {
	// 		color: $color-red;
	// 		font-size: 11.9px;
	// 	}
		
	// }
	
	.platform-input {
		min-width: 100px;
		border: none;
		width: 100%;
	}
	
	.contact-btn {
		width: 30px;
		height: 30px;
		padding: 0px;
	}

	.fp-btn {
		width: 30px;
		height: 30px;
		padding: 0px;
		margin-top: 40px;
	}
	
	.remove {
		&:hover {
			background-color: $color-red;
		}
	}

}