// .filtros-btn-container {
//     border: solid 2px red;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 12px 0;
// }
.filters-space-container {
    // border: 2px solid red;
    // width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}