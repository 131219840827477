@import '../abstracts/colors';

.dashboard {
	display: grid;
	grid-template-areas: "sidebar board";
	grid-template-columns: 300px auto;
	overflow-y: auto;
	overflow-x: hidden;
	margin: 0;
	padding: 0;
	height: 100vh;
	width: 100vw;
	// border-radius: 10px;

	&--hidden-sidebar {
		grid-template-areas: "board";
		grid-template-columns: 1fr;
	}

	.board {
		grid-area: board;
		overflow-y: auto;
		padding: 24px 24px 0 24px;
		position: relative;
	}

	.open-sidebar {
		position: absolute;
		top: 36px;
		left: 0;
		z-index: 10000;
		cursor: pointer;
	}
}