@import '../abstracts/colors';

.modal {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000052;

    .modal-content {
        background-color: $bg-primary;
        border-radius: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        min-width: 500px;
        height: fit-content;
        min-height: fit-content;
        // overflow: hidden;
        overflow-y: auto;
        overflow-x: hidden;
        overflow: visible;

        // border: 2px solid blue;
        
        &--medium {
            min-width: 900px !important;
        }

        &--large {
            height: 80vh !important;
            // height: fit-content;
            min-width: 1000px;
            max-height: 80%;
            overflow-x: hidden;
            overflow-y: auto;
            //overflow: visible;
            // border: 2px solid red;
        }
    }

    .modal-header {
        background-color: $black;
        border-radius: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 48px;
    }

    .modal-title {
        color: $yellow;
        font-size: 1.5rem;

        &--subtitle {
            color: $dark-grey;
            font-size: 1rem;
            margin: 24px;
        }
    }

    .modal-body {
        padding: 24px;
        height: fit-content;
        // height: 100%;
        max-height: 75vh;
        overflow-y: visible;

        .disable-modal {
            pointer-events: none;
        }

        .elementos-table {
            max-height: 50vh;
            overflow-y: auto;
            flex-direction: column;
            display: flex;
            position: relative;
            margin: 32px 0 0;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                background-color: $bg-tertiary;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $yellow;

            }

            .inline-grid-checkbox {
                max-width: 30px;
            }

        }
    }

    h3 {
        padding: 24px 0 12px;
        border-bottom: 1px solid $yellow;
        color: $text-primary;
        margin-bottom: 12px;
    }

    .double-col-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        position: relative;
        // height: 48px;
        margin-bottom: 18px;

        &>div {
            width: calc(50% - 10px);
        }

        & label:not(.error) {
            color: $text-primary;
        }

        &.with-add-btn {
            gap: 30px;

            &>div {
                width: calc(50% - 15px);

                & .input-container {
                    width: calc(100% - 30px);
                }
            }
        }
    }

    .buttons-right {
        display: flex;
        padding: 36px 0 24px;
        gap: 24px;
        align-items: center;
        justify-content: flex-end;
    }

    .justify-end {
        display: flex;
        justify-content: flex-end;
        // background-color: #e9e9e9;
    }

    .row-of-three {
        position: relative;
        display: inline-grid;
        width: 100%;
        height: 100%;
        grid-template-columns: repeat(3, 1fr);
        gap: 18px 48px;
        margin-bottom: 18px;
        // box-shadow: inset 0 0 0 2px red;

        &>* {
            width: 100%;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
}

.estudios-selected {
    // border: 2px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid $yellow;

    & label {
        font-size: 1rem;
    }

    & div {
        display: flex;
        align-items: center;
        gap: 12px;
        // border: 2px solid blue;
        height: 1rem;
        padding-bottom: 12px;
    }
}

.popover {
    background-color: white;
    border: 1px solid $yellow;

}

.popover-header {
    font-size: 1rem;
    color: $dark-grey;
    padding: 6px 12px;
    background-color: $hover-off;
}

.table-popover {
    padding: 6px 12px;
    font-size: 0.9rem;
    color: $dark-grey;

    & thead>tr>th,
    tbody>tr>td {
        font-size: 0.9rem;
        color: $black;
        text-align: center;
    }
}

.narrow-modal {
    .modal-content {
        min-width: fit-content;
        .desc-update-container {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }
    
}