.relative {
    position: relative;
}

.pointer {
    cursor: pointer;
}

.buttons-horizontal{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}