.label-button {
	align-items: center;
    background-color: #AAAAAA;
    border: none;
    border-radius: 4px;
    color: #FFFFFF;
    display: flex;
    font-size: 13px;
    height: 32px;
    justify-content: center;
    outline: none;
    padding: 0 20px;

    &:focus {
        outline: none !important;
    }

    &:hover {
        outline: none !important;
    }

    &:active {
        background-color: #818181;
    }

}