//Colors:
$_white: #FFFFFF;
$_greyed_white: #FDFDFD;
$_grey: #DEDEDE;
$_grey-light: #e9e9e9;
$_grey_light_transp: #e9e9e9AA;
$_grey_dark: #AAAAAA;
$_grey_darker: #818181;
$_black: #333333;

$_blue: #607D8B;
$_blue_light: #BEE5EB;
$_blue_darker: #0C5460;

$_green: #155724;
$_green_light: #C3E6CB;

$_red: #F44336;
$_red_light: #F5C6CB;
$_red_darker: #721C24;

$_orange: #ff9600;

$_yellow: #ffcc00;
$_yellow_transparent: #ffcc0030; 
$_yellow_light: #FFEEBA;
$_yellow_darker: #856404;

$pavasal: $_yellow;

$primary_color: $_grey;
$primary_dark_color: $_grey_darker;


$label_white: $_white;

$text_error: $_red;
$text_warning: $_orange;
$header_text_active: $_black;
$header_text_transparent: transparentize($color: $_grey_dark, $amount: 0.5);
$header_text: $_grey_dark;

$screen_background: $_grey-light;
$navigation_background: $_greyed_white;
$card_background: $_white;

//Estilos componente cobros
$pavasal-gray: #607D8B;
$gray-font: #999999;
$white-font: #FFFFFF;
$filter-element: #546e7a;

$card-background: white;
$card-filter-background: #e9e9e9;

$box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
$box-shadow-card: -2px 3px 10px 1px rgba(0, 0, 0, 0.15);
$box-shadow-button: 0px 3px 10px 1px rgba(0, 0, 0, 0.4);

$color-green: rgba(22, 181, 38, 0.855);
$color-red: #F44336;
$color-light-blue: #a5cde2;

// Sizes:
$xxs: 4px;
$xs: 8px;
$s: 12px;
$m: 16px;
$l: 24px;
$xl: 32px;

//fonts:
$font:'Roboto', sans-serif;