.tag {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	gap: 8px;
	border-radius: 10px;
	width: fit-content;
	padding: 4px 8px;
	label {
		vertical-align: middle;
	}
	button {
		vertical-align: middle;
		.icon {
			background-color: transparent;
			font-size: 20px;
		}
	}
}