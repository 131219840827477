$yellow: #ffc72c;
$light-yellow: #ffeb79; 

$white: #FFFFFF;
$black: #1D1D1B;
$dark-grey: #333F48;
$medium-grey: #ACACAC;
$light-grey: #cecece; 

$bg-inline: #DBDBDB;

$bg-primary: $white;
$bg-secondary: #dedede70;
$bg-tertiary: #e9e9e9;

$text-primary: #333F48;
$text-secondary: #b7b7b7; 

$hover-off: #f0f0f0;
$hover-on: $yellow;