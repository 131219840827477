@import '../../../../assets/styles/settings/colors';

.custom-input {
    display: flex;
    padding: 0px;
    padding-left: 0;
    // border-bottom: 1px solid  $gray-font;
    // border-bottom: 1px solid $_grey;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
    position: relative;
    
    }
    input {
        background-color: rgba(255, 255, 255, 0.589);
        border: none;
        border-radius: 0;
        color: #333F48;
        font-size: 11.9px;
        margin: 0;
        outline: none;
        padding: 8px;
        width: 100%;

        /* Chrome, Firefox, Opera, Safari 10.1+ */
        &::placeholder {
            color: #555555;
        }

        /* Internet Explorer 10-11 */
        &:-ms-input-placeholder {
            color: #555555;
        }

        /* Microsoft Edge */
        &::-ms-input-placeholder {
            color: #555555;
        }
    }

    i {
        font-size: 18px;
        color: #555555;
        margin-right: 10px;
    }

    &__label--error {
        color: $color-red;
        font-size: 12px;
    }

    &__error {
        border-color: $color-red;
    }

    &__date {
        display: flex;
        margin-left: 5px;
        max-width: 90%;
        font-size: small;
    }

    &__textarea {
        min-height: 50px;
        padding: 0 0 0 0;
    }
    
    &__full-width{
        width: 100%;
    }
}

.textarea {
    // display: block;
    // overflow: hidden;
    background-color: transparent;
    // box-shadow: inset 0 0 0 2px red;
    width: 100%;
    // height: 100%;
    // padding: 12px 0;
    resize: none;
    // height: 2rem;
    // position: absolute;
    // bottom: 12px;
    // left: 0;
    // color: $text-primary;
    // min-height: 10px;
    // height: 45px;
    // line-height: 15px;
    // margin-top: 0px;
    // margin-bottom: 5px;
    border: none;
    outline: none;
    margin: 0 auto;
}

.textarea[contenteditable]:empty::before {
    color: gray;
}

.full-width{
    width: 100%;
}

.secondary-input {
	border-bottom: none;
	height: auto !important;
}