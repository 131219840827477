@import '../variables';

.grid-container {
    padding: 10px;
    overflow-y: auto;
}

.grid-container > table > tbody > tr > td > .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}