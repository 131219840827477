@import '../abstracts/colors';

.grid-view-container {
    height: 100%;
    padding: 0;
    background-color: $white;
    position: relative;
    margin: 0;
    & h3 {
        color: $yellow;
        font-size: 1.5rem;
        font-weight: 400;
        background-color: $black;
        padding: 12px 48px;
        }
    & .filters-container {
        // border: 2px solid red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px;
        gap: 6px;
        // padding: 24px 0 38px 0;
        & > div {
            width: max-content;
        }
        .tag-cloud {
            display: flex;
            justify-content: center;
            width: 100%;
            gap: 6px;
        }
    }
    .grid-container {
        max-height: calc(100% - 53px - 45px - 24px - 60px);
        padding: 0 36px;
    // max-height: 80vh;
    }

    .download-excel {
        position: absolute;
        top: 57px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        padding: 14px 36px;
        & button {
            height: 31px;
            width: 31px;
            background-color: $yellow;
            & i {
                color: $dark-grey;
            }
        }
        // & > div  {
        //     display: flex;
        // }
    }

    & table {
        border-collapse: collapse; 
        height: 100%;
        width: 100%;
        & thead {
            border-bottom: 1px solid $yellow;
            position: sticky;
            top: 0;
            background-color: white;
            & tr > th {
                padding-bottom: 12px;
            }
            & label {
                position: relative;
            }
            & .header-cell {
                // border: 2px solid red;
                display: flex;
                gap: 6px;
                padding: 0 6px;
                flex-direction: row;
                align-items: flex-start;
                // align-items: center;
                color: $dark-grey;
                height: 100%;
                font-size: 13px;
                 .sorting-arrow {
                    height: 12px;
                    width: 12px;
                    font-size: 6px;
                    background-color: transparent;
                    color: $yellow;

                    &-sorted {
                        // border: solid 1px red;
                        height: 12px;
                        width: 12px;
                        font-size: 6px;
                        background-color: transparent;
                        color: $dark-grey;
                    }
                }  
            }
        }
        & tbody {
            & tr {
                border-bottom: 1px solid $yellow;
                color: $dark-grey;
                text-transform: uppercase;
                & td {
                    height: 3rem;

                    .number {
                        text-align: right;
                        padding-right: 18px;
                    }
                }
                .truncate-opp {
                    height: 3rem;
                    padding-right: 3px;
                }
                .truncate-opp-number {
                    height: 3rem;
                    padding-right: 18px;
                    text-align: right;
                }
                .truncate {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                    max-width: 0;
                    height: 3rem;
                }
                .link {
                    // border: solid 2px red !important;
                    cursor: pointer;
                    text-decoration: none;
                    color: $dark-grey;
                    &:hover {
                        color: $yellow;
                        font-weight: bold;
                    }
                }
            }
        }
        & .expand-oportunidad-btn {
            background-color: transparent;
            height: 12px;
            width: 12px;
            margin-right: 12px;
        }
    }

    .flex-horiz {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .expanded {
        position: static;
        top: 0;
        background-color: transparent;
    }
}