@import '../abstracts/colors';

.inline-grid {
    margin-top: 24px;
}

.inline-grid-container {
    overflow-y: scroll;
    max-height: 30vh;
    display: flex;
    flex-direction: column;
    position: relative;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: $bg-tertiary;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $yellow;
        
    }

     .inline-grid-checkbox {
        max-width: 30px;    
    }
}

.inline-grid-header {
    border-bottom: 1px solid $light-grey;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    width: 100%;

    .inline-header-checkbox {
        max-width: calc(30px + 12px);
    }

    & > *:not(:last-child) {
        width: 100%;
    }
    

    .inline-button {
        min-width: 89px;
        height: 31px;
        button {
            background-color: $hover-off !important;
            border-radius: 0 !important;
            height: 31px;
            width: 31px;
           i {
            color: $dark-grey;
           }
           &:hover {
            background-color: $yellow !important;
           }
        }
    }
}

.inline-buttons {
    display: flex;
    justify-content: space-between;
    // gap: 12px;
    min-width: 74px;

    button {
       background-color: $hover-off;
       border-radius: 0 !important;
       height: 31px;
       width: 31px;
       margin-left: 0;

       i, span {
        color: $dark-grey;
       
       }
       &:hover {
        background-color: $yellow !important;
       }
    }
}

.inline-grid-item {
    display: flex;
    background-color: transparent;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0px 12px 0px;
    gap: 12px;
    position: relative;
    width: calc(100% - 10px);
    & > *:not(:last-child)  {
        width: 100%;
    }
    & label, input {
        columns: $dark-grey;

    }

    .inline-grid-checkbox {
        height: fit-content;
        margin-right: 10px;
        background-color: $hover-off;
        & .check-input {
            box-shadow: inset 0 0 0 1px $light-grey;           
        }        
    }

    input[type=checkbox] {
        cursor: pointer;
        border-bottom: 0px;
    }

    .input-container > .inline-grid-checkbox {
        background-color: inherit;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 0;

        .check-input {
            position: relative;
            top:0;
            left:0;
        }  
    } 
}

.selected {
    background-color: $yellow;
}