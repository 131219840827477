@import '../variables';

.view-container {
    height: 100%;
    overflow-y: hidden;
    // border: 2px solid salmon;
    // padding: 10px;
    display: flex;
    // position: relative;
    flex-direction: column;
    justify-content: start;
    background-color:  $card_background;

    .download-excel {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: $s;
    }
}

.view-container-modal {
    height: calc(100% - 60px);
    // overflow-y: hidden;
    // border: 2px solid salmon;
    padding: 10px;
    display: flex;
    // position: relative;
    flex-direction: column;
    justify-content: start;
    background-color:  $card_background;
}