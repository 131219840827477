@import "../settings/colors";

.card-header {
	margin: 0px 0px 15px 0px;
	font-weight: normal;
	font-size: 20px;
}

thead {
	font-size: 13px;
	color: $header_text;
	font-weight: normal;
	text-align: left;

	th {
		padding: 0 10px 8px 0;
		.arrow {
			color: $header_text;

			&--sorted {
				color: $_black
			}
		}
	}
}