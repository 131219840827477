@import '../../../../assets/styles/abstracts/colors';

.date-selector-container {
	border-bottom: 1px solid $yellow;
	> label {
		font-size: 13px !important;
		font-weight: 400 !important;
	}
	.op-btn {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: transparent;
		width: 100%;
		height: 34px;
	}
	& button {
		font-size: 13px;
        color: $text-primary;

	}

}

.date-selector {
	.inputs {
		display: flex;
		flex-flow: row nowrap;
		gap: 24px; 
		margin: 12px 0;
		> .form-input {
			display: flex;
			gap: 6px;
			width: calc(250px - 24px);
			flex-direction: column;
		}
		> .form-input > input {
			padding: 3px 6px;
			border: none;
			// background-color: $hover-off;
			border: 1px solid $light-grey;
		}
	}

	.range-checks {
		display: grid;
		grid-template-columns: 250px auto;
		grid-template-rows: 30px auto;
		padding-top: 10px;
	}

	.year-periods {
		margin-top: 20px;
		display: grid;
		grid-template-columns: 250px auto;

		.years, .periods {
			.checks {
				margin-top: 10px;
				display: grid;
				grid-template-rows: 20px auto;
				gap: 10px;
			}
		}
	}

	.footer-actions {
		display: flex;
		gap: 24px;
		justify-content: flex-end;
		margin: 0 24px 24px 24px;
	}
}