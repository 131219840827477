@import "../settings/colors";

// .sidebar__navigation {
// 	background-color: $navigation_background;
// }

.dashboard,
.table-screen__filters,
.suggestionInput__item:hover,
.expanded-cell {
	background-color: $screen_background;
}

// .sidebar__version {
// 	background-color: $primary_color;
// }

.card-background {
	background-color: $card_background;
}

// .sidebar {
// 	background-color: $pavasal;
// }

.op-table__th,
.op-grid__th {
	background-color: $card_background;
}

.cancel {
	background-color: transparent;
}

.cancel:hover {
	background-color: $text_error;
}
