@import '../../../assets/styles/settings/colors';

button {
    align-items: center;
    border: none;
    border-radius: 4px;
    color: $_grey_darker;
    cursor: pointer;
    display: flex;
    font-size: 11.9px;
    height: 45px;
    justify-content: center;
    outline: none;
    padding: 0 5px;

    &:hover {
        background-color: $pavasal;

    }

    &:disabled {
        cursor: not-allowed;
        background-color: $_grey;
    }



}

.custom-button--ml8 {
    margin-left: 8px !important;
}

.custom-button--small {
    background-color: $filter-element;
    box-shadow: $box-shadow-button;
    padding: 0 5px;
}

.custom-button--mini {
    background-color: transparent;
    padding: 0;
    height: 1.5em;
}

.custom-button--medium {
    box-shadow: 5px 3px 10px 1px rgba(0, 0, 0, 0.4);
    padding: 20px 10px;
}

.custom-button__background--color {
    background-color: $color-red;
}

.custom-button--circle {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    .icon {
        font-size: 40px;
    }
}

.custom-button--close {
    height: 25px;
    width: 10px;
    background-color: $color-red;
}

.custom-button--favorite {
    padding: 20px 10px;
    &:hover {
        background-color: $color-green;
    }
}

.custom-button--delete {
    padding: 20px 10px;
    &:hover {
        background-color: $color-red;
    }
}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}
[data-title]:after {
    content: attr(data-title);
    background-color: white;
    color: #111;
    font-size: 12px;
    position: absolute;
    padding: 1px 5px 2px 5px;
    bottom: -1.6em;
    white-space: nowrap;
    box-shadow: 1px 1px 3px #222222;
    opacity: 0;
    border: 1px solid #111111;
    z-index: 99999;
    visibility: hidden;
}
[data-title] {
    position: relative;
}
