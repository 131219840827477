@import '../../../../assets/styles/abstracts/colors';
$listItemsBtnPadding: 0.3rem;

.dropdown {
	position: relative;
	// border: 2px solid green;
	// height: 100%;

	.op-btn {
		display: flex;
		justify-content: left;
		align-items:flex-start;
		background-color: transparent;
		width: 100%;
		// border: 2px solid green;
		height: fit-content;
		border-bottom: 1px solid $yellow;
		max-height: 21px;

		label {
			padding: 0;
			height: min-content;
			width: -webkit-fill-available;
    		display: flex;
			text-align: start;
			font-size: 13px;
			font-weight: 400;
			// border: 2px solid blue;
		}

		.icon {
			font-size: 15px;;
			font-weight: bold;
		}

		&.disabled {		
			cursor: not-allowed;
			background-color: #cecece;
			border-bottom: 1px solid $light-grey;
		}
	}

	&__list {
		max-height: 300px;
		overflow: auto;
		margin-top: 0;
		margin-bottom: 0;
		padding: 0;
		position: absolute;
		top: 34px;
		left: 0;
		z-index: 1;
		width: 100%;
		box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
		// border: solid red 2px;

		&--visible {
			display: block;
			animation-name: fadeIn;
			animation-duration: 0.3s;
			background-color: white;
		}

		&--hide {
			display: none;
		}

	}

	&__item {
		// white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis; 
		// padding: 0 !important;
		// margin: 0 !important;
		margin-bottom: $listItemsBtnPadding;

		&--first {
			margin-top: $listItemsBtnPadding;
		}

		&--last {
			border-bottom: 0;
		}

		button {
			padding: 7px 18px;
			display: flex;
			justify-content: left;
			align-items: center;
			text-align: left;
			width: 100%;
			cursor: pointer;
			font-size: 13px;
			border: none;
			height: fit-content;
			//background-color: inherit;

			.icon {
				font-size: 18px;
				margin-right: 6px;
			}
		}
	}

}