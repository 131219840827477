@import './abstracts/typography';

* {
  font-family: $font; 
}
body {
	font-size: 13px;
	margin: 0;
	width: 100vw;
	height: 100vh;
}

#root {
	height: 100%;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

button {
	border: none;
	cursor: pointer;
}

h1, h2, h3, h4, h5 {
	margin: 0;
}

font-user{
	font-weight: 700;
}

logout-button{
	-webkit-align-items: center;
    align-items: center;
    background-color: #607d8b;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    font-size: 11.9px;
    height: 32px;
    -webkit-justify-content: center;
    justify-content: center;
    outline: none;
    padding: 0 20px;
}
//////////////////////////////////////////////////////////////////////
/// ELEMENTS
//////////////////////////////////////////////////////////////////////
@import "./elements/headers";
@import "./elements/inputs";
@import "./elements/form";
@import "./elements/lists";
@import "./elements/buttons";
@import "./elements/tooltippopovers";
@import "./elements/footers";
@import "./elements/table";

//////////////////////////////////////////////////////////////////////
/// GENERIC
//////////////////////////////////////////////////////////////////////
@import "./generic/background";

//////////////////////////////////////////////////////////////////////
/// COMPONENTS
//////////////////////////////////////////////////////////////////////
@import "../../components/common/TableScreen/Table.Screen.scss";

//////////////////////////////////////////////////////////////////////
/// MODULES
//////////////////////////////////////////////////////////////////////
@import "../../modules/Oportunidades/OportunidadesAsfalto.scss";

//////////////////////////////////////////////////////////////////////
/// NPM PACKAGES
//////////////////////////////////////////////////////////////////////
@import 'react-toastify/dist/ReactToastify.css';
