@import '../abstracts/colors';

.mini-table {
    border-collapse: collapse;
    border: 1px solid $yellow;
    width: 100%;
    margin: $xl 0;

    td, tfoot {
        border: 1px solid $yellow;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        color: $text-primary;
        overflow-y: auto;
        max-width: 75px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
        padding: 5px;
        .large {
            max-width: 300px;
        }        

        &:has(input) {
            text-overflow: clip; // Change the text-overflow for td if input exists
        }
    }

    td.edited {
        background-color: $light-yellow;
    }

    th {
        font-weight: 600;  
        border: 1px solid $yellow;
        text-align: center;
        font-size: 13px;
        color: $text-primary;
        overflow-y: auto;
        max-width: 75px;
        .large {
            max-width: 300px;
        }
        overflow: hidden;
        padding: 5px;
    }
}


.mini-table-45 {
    border-collapse: collapse;
    border: 1px solid $yellow;
    width: 100%;
    margin: $xl 0;
    width: 45%;

    td, th, tfoot {
        border: 1px solid $yellow;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: $text-primary;
        overflow-y: auto;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
        padding: 5px;
        .large {
            max-width: 300px;
        }        

        &:has(input) {
            text-overflow: clip; // Change the text-overflow for td if input exists
        }
    }

    th {
        font-weight: 600;  
    }
}