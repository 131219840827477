@import '../../../assets/styles/variables';

.loading-container {
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    >.loading-item {
        border: 5px solid #f3f3f3;
        border-top: 5px solid rgb(255, 204, 0);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 