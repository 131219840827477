
.modal-section {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	.fields {
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		gap: 30px;
	}

	// .fields-modal-container {
	// 	min-width: fit-content;
	// }

	.grid-section {
		display: grid;
		grid-auto-flow: row;
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 10px;
	}
}

.content-fill{
    display: contents;
	}

.contact-name{
	color: gold;
    font-style: italic;
}