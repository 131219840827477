
.filter-section {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	.filters {
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		gap: 30px;
	}
	.tag-cloud {
		display: flex;
		flex-flow: row nowrap;
		gap: 8px;
	}

	.filters-modal-container {
		min-width: fit-content;
	}
}