$listItemsBtnPadding: 0.3rem;

.suggestionInput {
	display: flex;

	.disabled {
		cursor: not-allowed;
	}

	&__list {
		max-height: 300px;
		overflow: auto;
		margin-top: 34px;
		margin-bottom: 0;
		padding: 0;
		position: absolute;
		z-index: 1;
		width: 100%;
		box-shadow: 0 2px 10px rgba(0, 0, 0, .2);

	}

	&__list.visible {
		display: block;
		animation-name: fadeIn;
		animation-duration: 0.3s;
		background-color: white;

		&.banco {
			position: fixed;
			max-height: 200px;
			width: auto;
		}
	}

	&__list.hide {
		display: none;
	}

	&__item {
		margin-bottom: $listItemsBtnPadding;

		&--first {
			margin-top: $listItemsBtnPadding;
		}

		&--last {
			border-bottom: 0;
		}

		button {
			padding: 7px 18px;
			display: flex;
			align-items: center;
			text-align: left;
			width: 100%;
			cursor: pointer;
			font-size: 13px;
			border: none;
			background-color: inherit;
			justify-content: left;

			.icon {
				font-size: 18px;
				margin-right: 6px;
			}
		}
	}

}