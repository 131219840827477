@import "../../../assets/styles/settings/colors";

.clientes {
	&__grid {
		display: block;
		height: 59vh;
		overflow-y: auto;


		.footer-section {
			width: 100%;
		}
	}
}

.promotores-ofertas-form {
	
	.grid-section {
		display: grid;
		grid-auto-flow: row;
		grid-template-columns: 1fr 1fr;
		column-gap: 10px;
		
	}

	.grid-section-fp {
		display: grid;
		grid-auto-flow: row;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		column-gap: 10px;
		
	}

	.clientes-contacts {
		margin-top: 16px;
		.contacts-back {
			padding: 15px;
			box-shadow: none;
			border-radius: 0 5px 5px 5px;
			overflow-x: auto;
			overflow-y: auto;
			height: fit-content;
			max-height: 37vh;
		}
	}
	
	.contacts--grid-header, .contacts--grid-item {
		color: $primary_dark_color;
		font-size: 13px;
		display: grid;
		gap: 15px;
		grid-template-columns: repeat(6, minmax(100px, 1fr)) 40px 40px;
		grid-auto-flow: column dense;
		padding: 0px 8px 8px 0px;
		label {
			min-width: 100px;
			margin-bottom: 5px;
		}
	}
	
	.contacts--grid-header--fp, .contacts--grid-item--fp {
		color: $primary_dark_color;
		font-size: 13px;
		display: grid;
		gap: 15px;
		grid-template-columns: repeat(4, minmax(190px, 1fr)) 40px 40px;
		grid-auto-flow: column dense;
		>div {
			padding-left: 10px;
		}
		>label {
			min-width: 100px;
			padding-left: 10px;
		}
	}
	.contacts--grid-header {
		align-items: center;
		margin-bottom: 8px;
		padding-bottom: 0px;
	}
	
	.contacts--grid-item {
		align-items: center;
		background-color: #a5cde286; 
		min-width: fit-content;
		position: relative;
	}

	.contacts--grid-header--fp {
		align-items: center;
		margin-bottom: 8px;
		padding-bottom: 0px;
		width: calc(100% - 16px);
	}

	.contacts--grid-item--fp {
		align-items: center;
		background-color: #a5cde286; 
		min-width: fit-content;
	}
	
	.contact-dropdown {
		text-align: center;
		min-width: 100px;
		height: min-content;
		font-size: 11.9px;
		background-color: $pavasal-gray;
		padding: 8px 0 8px 0;
		border-radius: 5px;
		color: $white-font;
		cursor: pointer;
		&:hover {
			background-color: $pavasal;
		}
	
		.contact-dropdown-content {
			z-index: 99;
			position: fixed;
			height: min-content;
			.dropdown-item {
				.dropdown-options__option {
					padding: 0px;
				}
				padding: 0 0 0 8px;
				height: 25px;
				min-width: 100px;
	
				i {
					font-size: 18px;
				}
			}
	
		}
	}
	
	.dropdown-cell {
		display: grid;
		grid-template-rows: auto 30%;
		label {
			color: $color-red;
			font-size: 11.9px;
		}
		
	}
	
	.platform-input {
		min-width: 100px;
		border: none;
		width: 100%;
	}
	
	.contact-btn {
		width: 30px;
		height: 30px;
		padding: 0px;
	}

	.fp-btn {
		width: 30px;
		height: 30px;
		padding: 0px;
		margin-top: 40px;
	}
	
	.remove {
		&:hover {
			background-color: $color-red;
		}
	}

}