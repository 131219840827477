@import '../abstracts/colors';

    .btn-primary {
        background-color: $yellow !important;
        height: 32px;
        padding: 12px 24px !important;
        color: $black;
        font-size: 13px;
        &:disabled {
            background-color: $bg-primary !important;
            color: $text-secondary;
        }
        & span {
            font-size: 18px;     
            color: $dark-grey;
            margin-right: 6px;
        }
        & button {
            background: none;
            width: 18px;
            height: 18px;
            margin-right: 6px;
        }
        & svg {
            & path {
                fill: $dark-grey;
            }
        }
        &:hover {
            font-weight: 700;
        }
    }

    .add-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        height: 24px;
        width: 24px;

        &.transform-translate-y-100 {
            transform: translateY(-100%);
        }
    }

    .add-btn-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        height: 24px;
        width: 24px;
        // right: -30px;
        display: flex;
        // justify-content: flex-end;
        align-items: center;
    }

    .bottom-buttons {
        margin-top: 32px;
        display: flex;
        justify-content: flex-end;
        gap: 48px;
    }

    .abs-right {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .abs-right-30 {
        position: absolute;
        top: 0;
        right: -30px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .btn--link {
        text-decoration: none;
    }