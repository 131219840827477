.grid-template {
	&__grid {
		display: block;
		height: 65vh;
		overflow-y: auto;


		&__footer-section {
			width: 100%;
		}
	}
}