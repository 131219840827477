@import '../abstracts/colors';

form fieldset,
.modal-content fieldset {
	border: none;
	padding: 0;
	margin: 0;	
}

.form-group {
	width: 100%;
	color: $primary_dark_color;
	display: inline-table;

	.form-line {
    position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;	
	width: 100%;
	height: 50px;
		&--error {
			border-bottom: 2px solid $text_error;
		}

		.flex {
			display: flex;
		}
	}

	.form-control {
		padding-left: 0;
	}

	&--input {
		width: 100%;
	}

	&--inline {
		width: 100%;
	}

	&--selector {
		color: $primary_dark_color;
		width: 100%;
	}

}

.form-input {
	.error {
		color: $text_error;
		font-size: 10px;
	}

	&-litle{
		width: 20%;
	}

	&--disabled {
		border-bottom: 1px solid $light-grey;
		cursor: not-allowed;

		label, span, input {
			color: $light-grey;
		}
	}
}