.contratista-filter {
	display: flex;
	width: 500px;

	.selector {
		width: 190px;
	}

	.search {
		margin-left: 10px;
		width: 300px;

	}
}