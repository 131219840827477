@import "../settings/colors";


.grid-template__footer-section,
.footer-section {
	// border: 2px solid blue;
	position: absolute;
	width: calc(100% - 50px);
	bottom: 5px;
	left: 25px;
	.pagination {
		color: $header_text;

		.pagination-select {
			color: inherit;
		}

		.icon {
			color: $header_text !important;
		}

	}
}