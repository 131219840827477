@import "../settings/colors";

.btn {
	padding: 0;
	background-color: transparent;
	cursor: pointer;
	color: $black;
	& button {
		font-size: 2rem;
	}

	&--primary {
	// &--primary, &--cancel {
		background-color: $screen_background;
		border-radius: 5px;
		padding: 8px 12px;
	}

	&--primary:hover {
		background-color: $pavasal;
	}

	// &--cancel:hover {
	// 	background-color: $text_error;
	// 	color: $label_white;
	// }

	&--small {
		border-radius: 5px;
		height: 20px;
		width: 20px;
	}

	&--suggestion {
		border-radius: 5px;
		margin-top: 7px;
		height: 20px;
		width: 20px;
	}

	&--icon {
		padding: 0;
		border-radius: 0.5rem;
		height: min-content;
		width: min-content;
		
	}

	&--selector {
		width: 100%;
	}
}

