.pagination {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	.arrow-btns {
		display: flex;
		align-items: center;

		span {
			vertical-align: center;
		}

		.btn {
			height: 24px;
		}
		.btn.disabled {
			cursor: not-allowed;
		}
	}

	&__input {
		width: min-content;
	}

	.page-selector,
	.results-controller {
		select {
			margin-left: 8px;
			border-radius: 4px;
			height: 28px;
			padding: 0 5px;
		}
	}
}