@import "../../../../assets/styles/settings/colors";

.dropdown-options__option {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding-right: 8px;
  white-space: nowrap;
  justify-content: space-between;
  max-width: 300px;
  white-space: pre-wrap;
  overflow-wrap: anywhere;

  &:hover {
    background-color: $pavasal-gray;
    color: $white-font;
    
    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid $screen-background;
  }
}

.dropdown-options__item {
  width: 100%;
  padding: 8px;

  .icon {
    font-size: 15px;;
  }
}

.dropdown-options__option-click {
  width: 100%;
  padding: 14px;
}
